<script>
/* COMPONENT DOCUMENT
* author: David.dai
* date: 2020/07/16
* desc: 开证申请
*/

import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";

export default {
  components: {
    nfBreadcrumb
  },

  data() {
    return {
      activeNav: 0,
      tabsNav: [
        {
          name: "待办申请",
          url: "upcomingApply"
        },
        {
          name: "全部申请",
          url: "allApply"
        }
      ],
      breadList: [
        {
          name: "业务协同"
        },
        {
          name: "开证申请"
        }
      ]
    };
  },
  methods: {
  },
  created() {
    const path = this.$route.path;
    if (path === "/issuingApply/upcomingApply") {
      this.activeNav = 0;
    } else if (path === "/issuingApply/allApply") {
      this.activeNav = 1;
    }
  }
};
</script>

<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>

    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        >
          <i v-if="nav.name === '待办申请'" class="icon icon-approval"></i>
          <i v-if="nav.name === '全部申请'" class="icon icon-application"></i>
          <span>{{nav.name}}</span>
        </router-link>
      </div>
      <div class="nf-tabs-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-approval {
      background-image: url("../../assets/images/dutyManagement/icon_approval.png");
    }

    &-application {
      background-image: url("../../assets/images/dutyManagement/icon_application.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
